@tailwind base;
@tailwind components;
@tailwind utilities;

.caché {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
}
.pascaché {
    opacity: 1;
    filter: blur(0);
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

.cloudeffect {
    animation: float 6s ease-in-out infinite;
}
::-webkit-scrollbar {
	width: 10px; 
}
::-webkit-scrollbar-thumb {
	background: #002BB7;
	border-radius: 25px;
}
* {
	scrollbar-width: thin; 
	scrollbar-color: #002BB7; 

}